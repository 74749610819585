// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karir-js": () => import("./../../../src/pages/karir.js" /* webpackChunkName: "component---src-pages-karir-js" */),
  "component---src-pages-trulav-about-us-js": () => import("./../../../src/pages/trulav/about-us.js" /* webpackChunkName: "component---src-pages-trulav-about-us-js" */),
  "component---src-pages-trulav-community-guide-js": () => import("./../../../src/pages/trulav/community-guide.js" /* webpackChunkName: "component---src-pages-trulav-community-guide-js" */),
  "component---src-pages-trulav-faq-js": () => import("./../../../src/pages/trulav/faq.js" /* webpackChunkName: "component---src-pages-trulav-faq-js" */),
  "component---src-pages-trulav-privacy-policy-js": () => import("./../../../src/pages/trulav/privacy-policy.js" /* webpackChunkName: "component---src-pages-trulav-privacy-policy-js" */),
  "component---src-pages-trulav-term-condition-js": () => import("./../../../src/pages/trulav/term-condition.js" /* webpackChunkName: "component---src-pages-trulav-term-condition-js" */)
}

